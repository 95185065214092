import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Location } from '@reach/router'
import PropTypes from 'prop-types'
import Collapsible from "react-collapsible"
import Dropdown from '../dropdown/Dropdown'
import './../layouts/header.scss';

function handleAccordion(newPosition) {
    console.log(newPosition)
}


class Header extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({ menu: !this.state.menu })
    }

    componentDidMount() {
        window.addEventListener("scroll", e => {
            const nav = document.querySelector(".navbar")
            const headerArea = document.querySelector(".header_area")
            if (nav != null) {
                if (nav.offsetTop != null) {
                    var sticky = nav.offsetTop
                    if (window.pageYOffset > sticky) {
                        nav.classList.add("navbar_fixed")
                        headerArea.classList.add("navbar_fixed")
                    } else {
                        nav.classList.remove("navbar_fixed")
                        headerArea.classList.remove("navbar_fixed")
                    }
                }
            }
        })

    }
    render() {

        const show = (this.state.menu) ? "show" : "";
        return (
                    <header className={`header_area header_area_six ${(this.props.isModalOpen != null) ? (this.props.isModalOpen == true) ? 'd-none' : 'navbar_fixed' : ''}`}>
                    <nav className="navbar navbar-expand-lg menu_one">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/es"><img className="logo-sm" src="/logo.png" srcSet="/logo2x.png 2x" alt="logo" /></Link>
                            {/* <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> */}
                            <div className='mobile-navbar-btns'>
                                <Dropdown className="language-dropdown" location={this.props.location.pathname}/>
                                <button className="navbar-toggler collapsed" type="button" onClick={this.toggleMenu} aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="menu_toggle">
                                        <span className="hamburger">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </span>
                                        <span className="hamburger-cross">
                                            <span></span>
                                            <span></span>
                                        </span>
                                    </span>
                                </button>
                            </div>
                            <div id="navbarSupportedContent" className={`collapse navbar-collapse ` + show}>
                                <ul className="navbar-nav m-auto menu">
                                    <li className="nav-item dropdown submenu mega_menu">
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${this.props.location.pathname.includes('what-is') ? 'active' : ''}`} to="/about/what-is-workhub" role="button">
                                        Que es Workhub
                                    </Link>

                                    </li>
                                    <li className="nav-item dropdown submenu">
                                        <Link className={`nav-link dropdown-toggle ${this.props.location.pathname.includes('features') ? 'active' : ''}`} to="/features/" role="button">
                                        Características
                                    </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className={`nav-link ${this.props.location.pathname.includes('pricing') ? 'active' : ''}`} to="/pricing/" role="button">
                                        Precios
                                    </Link>

                                    </li>
                                    <li className="nav-item">
                                        <Collapsible
                                            trigger={
                                                <div id="hideCollapse" style={{fontWeight: '500', color: 'black'}}>
                                                Resources
                                                </div>
                                            }
                                            triggerWhenOpen={
                                                <div id="hideCollapse" style={{fontWeight: '500', color: 'black'}}>
                                                Resources
                                                </div>
                                            }
                                            transitionTime={200}
                                        >
                                            <div id="hideCollapse">
                                                <ul>
                                                    <li><Link to="/about/news/">Release Notes</Link></li>
                                                    <li><a href="https://portal.productboard.com/workhub/" rel="noopener noreferrer">Product Roadmap</a></li>
                                                    <li><Link to="/resources/partners/">Partners</Link></li>
                                                    <li><a href="http://help.workhub.com/en/" target="_blank" rel="noopener noreferrer">Help</a></li>
                                                    <li><Link to="/features/training/">Training Courses</Link></li>
                                                    <li><a href="https://www.youtube.com/channel/UC7jQkB2O_sVOUsylseAp9RA">WorkhubU</a></li>
                                                    <li><Link to="/resources/blog/">Workhub Blog</Link></li>
                                                </ul>
                                            </div>
                                        </Collapsible></li>

                                </ul>
                                <div className="tracking_btn">
                                    <Link className="btn_get btn_hover" to="/signup/es">Regístrese Gratis</Link>
                                    <a className="agency_banner_btn  btn_hover" href="https://app.workhub.com">Ingreso</a>
                                </div>
                                <div className='language-options' style={{marginLeft:'10px'}}>
                                    <Dropdown />
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
        )
    }
}

export default props => (
    <Location>
        {locationProps => <Header {...locationProps} {...props} />}
    </Location>
);