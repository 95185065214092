import React, { useState, useEffect, useContext } from 'react'
import FsLightbox from 'fslightbox-react';
import './demo.scss'
// import LocalSession from './LocalSession'

function SubscribeTwoES({location, errors}) {

  // LightBox Toggle State
  const [us, setUS] = useState(false)
  const [ca, setCA] = useState(false)

  const usFrame = (
    <div className="modal-contain">
      <iframe
        className='icontain-modal'
        src="https://meetings.hubspot.com/leo-skinner/meet-with-leo-skinner?uuid=f1884171-694d-4474-8999-56e6efdee183"
        width="1000px"
        height="1000px"
        scrolling="no"
        frameBorder="0"
        style={{overflow: 'auto'}}
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </div>
  )

  const caFrame = (
    <div className="modal-contain">
      <iframe
        className="icontain-modal"
        src="https://meetings.hubspot.com/leo-skinner/meet-with-leo-skinner?uuid=f1884171-694d-4474-8999-56e6efdee183"
        width="1000px"
        height="1000px"
        scrolling="no"
        frameBorder="0"
        style={{overflow: 'auto'}}
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </div>
  )

  return (
    <div>
      <section className="payment_subscribe_area">
        <div className="container">
          <div className="row">
            <div className="payment_subscribe_info ">
              <div className="payment_subscribe_content col-lg-6">
                <h2>¿Aún tiene dudas?</h2>
                <p>Permítale a nuestro equipo de ventas darle un tour para responder todas sus preguntas.</p>
              </div>
              <div className="payment_subscribe_content col-lg-4 offset-lg-2" style={{ textAlign: 'center' }}>

                  <><button type="button"
                  onClick={() => setCA(!ca)}
                  onKeyDown={() => setCA(!ca)}
                  className="btn_hover btn_four demo_canada">
                   Agende una Demostración</button>
                  <FsLightbox
                    toggler={ca}
                    sources={[caFrame]}
                  />
                  </>


              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SubscribeTwoES
