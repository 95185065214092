import React, { Fragment, useState, useEffect, createContext } from "react"
import { Helmet } from "react-helmet"
import Footer from "./Footer"
import Header from "./Header"
import SubscribeTwoES from "../common/SubscribeTwoES"
import SEO from "../common/SEO"
import FsLightbox from "fslightbox-react"
import "../../stylesheets/main.scss"
import { UserContext } from "./UserContext"
import StephenDemo from '../common/StephenDemo'
import EvanDemo from '../common/EvanDemo'

export default props => <LayoutES {...props} />


const LayoutES = props => {
  const pagelocation = props.location
  const lightbox = props.sources
  let header = <Header isModalOpen={props.isModalOpen} />
  let footer = <Footer />


  // Start Fetch Logic

  const [hasError, setErrors] = useState(false);
  const [location, setLocation] = useState(undefined);
  const [pathname, setPathname] = useState(undefined)
  const [data, setData] = useState(null);

  useEffect(() => {

    if (document.cookie.includes("location")) {

      // console.log("didn't fetch")

      // console.log(document.cookie.includes("location"))

      const getCookies = document.cookie.split(';')
        .map(cookie => cookie.split('='))
        .reduce((accumulater, [key, value]) =>
          ({ ...accumulater, [key.trim()]: decodeURI(value) }),
          {});

      setLocation(getCookies.location)

    } else {

      // console.log('did fetch')
      getIP()
    }

    setPathname(document.location.pathname)

  }, [data]);

  const getIP = async () => {

    fetch("https://telize-v1.p.rapidapi.com/location?callback=getlocation", {
      "method": "GET",
      "headers": {
        "x-rapidapi-key": "0c29885a2amsh6001eb80c0d42bap10bc85jsn14bbf33c3d85",
        "x-rapidapi-host": "telize-v1.p.rapidapi.com"
      }
    }).then(function (response) {
      // The API call was successful!
      return response.text();
    }).then(function (html) {

      // Clean up string
      let cleanStr = html.replace("getlocation(", '')
      let stripStr = cleanStr.replace(");", '')

      // Turn str into object
      let location = JSON.parse(stripStr);

      // Set state to country
      setLocation(location.country_code);

      // console.log(document.cookie.includes("location"))

      document.cookie = "location=" + location.country_code

      // console.log('I ran!')

    }).catch(function (err) {
      // There was an error
      setErrors(err);
    });
  }

  let subscribeBanner = <SubscribeTwoES errors={hasError} location={location} />

  // if (pathname === "/resources/partners/become-a-partner") {
  //   subscribeBanner = <StephenDemo location={location} errors={hasError}/>
  // }

  // if (pathname === "/resources/landing/osha/") {
  //   subscribeBanner = <EvanDemo location={location} errors={hasError}/>
  // }

  // End fetch logic

  let lightBox = <div />

  if (pagelocation !== undefined) {
    if (props.location.pathname.includes("signup")) {
      header = <header></header>
      footer = <footer></footer>
      subscribeBanner = <div></div>
    }
  }

  if (lightbox !== undefined) {
    lightBox = <FsLightbox toggler={props.toggler} sources={props.sources} />
  }

  return (
    <UserContext.Provider value={location}>
    <Fragment>
      <SEO />
      <Helmet>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
        />
        <link
          rel="stylesheet"
          href="/vendors/animation/animate.css"
          async
        ></link>
        <html lang="en" />
      </Helmet>
      {lightBox}
      {header}
      <main>
        {props.children}
        {subscribeBanner}
      </main>
      {footer}
    </Fragment></UserContext.Provider>
  )
}
